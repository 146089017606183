import React from "react"
import { Grid, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  mainImg: {
    position: "absolute",
    height: "100vh",
    width: "100%",
    overflow: "hidden",
    objectFit: "cover",
  },
  halfImg: {
    position: "absolute",
    height: "100vh",
    width: "50%",
    overflow: "hidden",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  imgGrid: {
    height: "100vh",
  },
  smallImgWrapper: {
    height: "100px",
  },
  smallImg: {
    width: "100%",
    maxHeight: "300px",
    objectFit: "cover",
  },
  twoImagesWrapper: {
    justifyContent: "space-evenly",
    marginTop: "5%",
    marginBottom: "3%",
  },
  twoImageBig: {
    height: "450px",
    width: "37vw",
    overflow: "hidden",
    objectFit: "cover",
  },
  twoImageSmall: {
    height: "450px",
    width: "25vw",
    objectFit: "cover",
    marginLeft: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
}))

export const FullBgContainer = props => {
  return (
    <Grid container spacing={0}>
      {props.children}
    </Grid>
  )
}

export const FullBg = props => {
  const classes = useStyles()

  return (
    <Grid className={classes.imgGrid} item xs={12} sm={12}>
      <img src={props.src} className={classes.mainImg} alt={props.alt} />
    </Grid>
  )
}

export const HalfBg = props => {
  const classes = useStyles()

  return (
    <>
      <Grid className={classes.imgGrid} item xs={12} sm={6}>
        <img src={props.src} className={classes.halfImg} alt={props.alt} />
      </Grid>
      <Grid item xs={6} sm={6}></Grid>
    </>
  )
}

export const SmallLeftBg = props => {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={0}>
        <Grid item className={classes.smallLeftImg} xs={9} sm={8}>
          <img className={classes.smallImg} src={props.img} alt={props.name} />
        </Grid>
        <Grid item xs={3} sm={4}></Grid>
      </Grid>
    </>
  )
}

export const SmallRightBg = props => {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={3} sm={4}></Grid>
        <Grid item className={classes.smallImgWrapper} xs={9} sm={8}>
          <img className={classes.smallImg} src={props.img} alt={props.name} />
        </Grid>
      </Grid>
    </>
  )
}

export const TwoImages = props => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={classes.twoImagesWrapper}>
      <Hidden only="xs">
        <Grid item sm={7}>
          <Img
            fluid={props.fluidBig}
            className={classes.twoImageBig}
            src={props.imgBig}
            alt={props.name}
          />
        </Grid>
      </Hidden>
      <Grid item sm={5} xs={12}>
        <Img
          fluid={props.fluidSmall}
          className={classes.twoImageSmall}
          src={props.imgSmall}
          alt={props.nameSmall}
        />
      </Grid>
    </Grid>
  )
}
