module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "매건프로젝트 Magan Project", // Navigation and Site Title
  titleAlt: "매건프로젝트", // Title for JSONLD
  description:
    "가장 트렌디한 웨딩, 기업행사 디렉팅 디자인, 플라워 데코레이션, 매건 프로젝트에서 시작하세요",
  keywords: `웨딩디렉팅, 웨딩데코, 스몰웨딩디렉팅, 야외웨딩, 웨딩데코레이션, 웨딩꽃장식, 기업행사기획, 기업행사, 이벤트기획, 이벤트장식, 웨딩부케, 플라워디스플레이, 플라워디피 `,
  headline: "매건프로젝트 웨딩디렉팅 웨딩 플라워 데코레이션", // Headline for schema.org JSONLD
  url: "https://maganproject.com", // Domain of your site. No trailing slash!
  siteLanguage: "kr", // Language Tag on <html> element
  image: "/src/images/indexMain2.jpg",
  logo: "/src/images/favicon.png", // Used for SEO
  ogLanguage: "ko_KR", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/images/favicon.png", // Used for manifest favicon generation
  shortName: "매건프로젝트", // shortname for manifest. MUST be shorter than 12 characters
  author: "매건프로젝트", // Author for schemaORGJSONLD
  themeColor: "#484646",
  backgroundColor: "#ffffff",
  socialLinks: {
    instagram: "https://www.instagram.com/magan_project",
    naver: "https://blog.naver.com/maganproject",
    email: "hongsg0113@naver.com",
    phone: "010-6583-0113",
    address: "서울 마포구 와우산로 10길 29",
  },
  googleAnalyticsID: "",
}
