import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../components/layout"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import { TwoImages } from "../components/imgBackground"
import { OutlineButton } from "../components/button"
import SEO from "../components/seo"
import website from "../../config/website"
import gsap from "gsap"
import { Power3 } from "gsap/gsap-core"

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: "0.9rem",
    fontFamily: '"Noto Sans KR", sans-serif',
    lineHeight: 1.8,
    color: "#6b6767",
  },
  container: {
    display: "flex",
    padding: "5% 18% 1% 18%",
    width: "100%",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 5% 5%",
      marginTop: "25%",
    },
  },
  articleTitleWrapper: {
    fontSize: "1.1em",
    textAlign: "center",
    color: "#636262",
  },
  articleEventType: {
    opacity: 0,
    fontFamily: '"Karla", sans-serif',
    textTransform: "uppercase",
    letterSpacing: "0.3em",
    fontSize: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
  articleTitle: {
    opacity: 0,
    lineHeight: 1.5,
    marginTop: "1%",
    fontFamily: '"Nanum Myeongjo", serif',
    fontWeight: 400,
    padding: "0 50px",
    fontSize: "3.3em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
      padding: "0 0",
    },
  },
  itemTitle: {
    fontFamily: '"Karla", sans-serif',
    textTransform: "uppercase",
    letterSpacing: "0.3em",
    fontSize: "0.8rem",
    lineHeight: 2.5,
    color: "#000000",
  },
  itemDesc: {
    fontFamily: '"Noto Sans KR", sans-serif',
    letterSpacing: "0em",
    fontSize: "0.8rem",
    lineHeight: 2.5,
  },
  blogBody: {
    paddingTop: "5%",
    justifyContent: "center",
  },
  colorTag: {
    width: "16px",
    height: "16px",
    display: "inline-block",
    borderRadius: "50%",
    marginRight: "5px",
  },
  imgGaro: {
    marginTop: "10px",
    width: "100%",
    padding: "5px",
    height: "600px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
  },
  bottomContainer: {
    paddingLeft: "1%",
  },
}))

export const query = graphql`
  query($slug: String!) {
    contentfulMaganBlog(slug: { eq: $slug }) {
      id
      title
      slug
      client
      projectType
      date
      tag
      place
      createdAt(formatString: "MMMM Do, YYYY")
      mainImage {
        file {
          url
        }
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyContentfulFluid
        }
        title
      }
      secondImage {
        file {
          url
        }
        title
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyContentfulFluid
        }
      }
      editorBody {
        json
      }
    }
  }
`

const Contentful = props => {
  const classes = useStyles()
  const eventDesc = useRef(null)
  const eventDesc1 = useRef(null)
  const eventTitle = useRef(null)
  const contentfulList = Array.from(props.data.contentfulMaganBlog.tag)
  const Text = ({ children }) => (
    <Grid item sm={12} xs={12}>
      <p>{children}</p>
    </Grid>
  )

  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        const imgWidth =
          node.data.target.fields.file["en-US"].details.image.width
        const imgHeight =
          node.data.target.fields.file["en-US"].details.image.height

        return imgWidth < imgHeight ? (
          <Grid item sm={6} xs={12}>
            <img
              alt={alt}
              src={url}
              style={{
                marginTop: "10px",
                width: "100%",
                padding: "5px",
                height: "660px",
                objectFit: "cover",
              }}
            />
          </Grid>
        ) : (
          <Grid item sm={12}>
            <img alt={alt} src={url} className={classes.imgGaro} />
          </Grid>
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }

  useEffect(() => {
    gsap.to(eventTitle.current, 1, {
      opacity: 1,
      y: 10,
      ease: Power3.easeOut,
      delay: 0.8,
    })
    gsap.to(eventDesc.current, 0.7, {
      opacity: 1,
      y: 10,
      ease: Power3.easeOut,
      delay: 0.3,
    })
    gsap.to(eventDesc1.current, 1.5, {
      opacity: 1,
      y: 10,
      ease: Power3.easeOut,
      delay: 1.2,
    })
  }, [])

  return (
    <Layout>
      <SEO
        title={`${props.data.contentfulMaganBlog.title} | ${website.titleAlt}`}
        postImage={props.data.contentfulMaganBlog.secondImage.file.url}
        desc={`${props.data.contentfulMaganBlog.place}, ${props.data.contentfulMaganBlog.projectType}, ${props.data.contentfulMaganBlog.title}`}
        node={props.data.contentfulMaganBlog}
        article
      />
      <div className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item sm={12} xs={12} className={classes.articleTitleWrapper}>
            <span className={classes.articleEventType} ref={eventDesc}>
              {props.data.contentfulMaganBlog.projectType}
            </span>
            <h1 className={classes.articleTitle} ref={eventTitle}>
              {props.data.contentfulMaganBlog.title}
            </h1>
            <span className={classes.articleEventType} ref={eventDesc1}>
              published on <br />
              {props.data.contentfulMaganBlog.createdAt}
            </span>
          </Grid>
          <TwoImages
            imgBig={props.data.contentfulMaganBlog.secondImage.file.url}
            name={props.data.contentfulMaganBlog.secondImage.title}
            fluidBig={props.data.contentfulMaganBlog.secondImage.fluid}
            imgSmall={props.data.contentfulMaganBlog.mainImage.file.url}
            nameSmall={props.data.contentfulMaganBlog.mainImage.title}
            fluidSmall={props.data.contentfulMaganBlog.mainImage.fluid}
          />
          <Grid container>
            <Grid item sm={2} xs={5}>
              <span className={classes.itemTitle}>
                Client
                <br />
                place <br />
                event Type <br />
                color <br />
              </span>
            </Grid>
            <Grid item sm={6}>
              <span className={classes.itemDesc}>
                {!props.data.contentfulMaganBlog.client
                  ? "n/a"
                  : props.data.contentfulMaganBlog.client}
                <br />
                {props.data.contentfulMaganBlog.place || "n/a"} <br />
                {props.data.contentfulMaganBlog.projectType} <br />
                {contentfulList.map((el, i) => {
                  return (
                    <span
                      className={classes.colorTag}
                      style={{ backgroundColor: `${el}` }}
                      key={i}
                    />
                  )
                })}
                <br />
              </span>
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: "7vh" }}>
            <span className={classes.itemTitle}>Project Details</span>
          </Grid>
          <Grid container className={classes.blogBody}>
            {documentToReactComponents(
              props.data.contentfulMaganBlog.editorBody.json,
              options
            )}
          </Grid>
          <Grid container className={classes.bottomContainer}>
            <OutlineButton to="/contact" buttonName="문의하기" />
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export default Contentful
